import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

import { useAuthStore } from "@/pstore/auth";
import { useSupervisionStore } from "@/pstore/supervision";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "test",
        name: "test",
        component: () => import("@/views/app_v2/test/indexView.vue"),
        children: [
          {
            path: "father",
            name: "test",
            component: () => import("@/views/app_v2/test/fatherItem.vue"),
          },
        ],
      },
      {
        path: "dashboard",
        name: "dashboard",
        // component: () => import("@/views/Dashboard.vue"),
        component: () => import("@/views/app_v2/supervision/ListCards.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboard"],
        },
      },
      {
        path: "/supervision",
        name: "Supervisión",
        // beforeEnter: (to, from, next) => {
        // const supervisionStore = useSupervisionStore();
        // supervisionStore
        //   .apiGetHistorySales()
        //   .then(() => {
        //     next();
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //     next({ name: "dashboard" });
        //   });
        // },
        component: () => import("@/views/app_v2/supervision/indexView.vue"),
        meta: {
          pageTitle: "Supervisión",
          breadcrumbs: ["Supervisión"],
        },
        children: [
          {
            path: "branches",
            name: "supervision-branches",
            component: () => import("@/views/app_v2/supervision/indexView.vue"),
            meta: {
              pageTitle: "Supervisión",
              breadcrumbs: ["Supervisión", "Sucursales"],
            },
            children: [
              {
                path: "",
                name: "supervision-branches-list-cards",
                component: () =>
                  // import("@/views/app_v2/supervision/ListCards.vue"),
                  import("@/views/app_v2/branches/ListCards.vue"),
                meta: {
                  pageTitle: "Sucursales",
                  breadcrumbs: ["Supervisión", "Sucursales"],
                },
              },
              {
                path: ":branchId",
                name: "supervision-branches-detail",
                component: () =>
                  import("@/views/app_v2/supervision/Detail.vue"),
                meta: {
                  pageTitle: "Sucursales",
                  breadcrumbs: ["Supervisión", "Sucursales", "Detalle"],
                },
              },
            ],
          },

          {
            path: "questionnaires",
            name: "questionnaires-release-pagination",
            component: () =>
              import("@/views/app_v2/questionnaires/pagination.vue"),
            meta: {
              pageTitle: "Cuestionarios",
              breadcrumbs: ["Operaciones", "Cuestionarios"],
            },
          },
        ],
      },
      {
        path: "/operations",
        name: "Operaciones",
        component: () => import("@/views/app_v2/operations/indexView.vue"),
        meta: {
          pageTitle: "Operaciones",
          breadcrumbs: ["Operaciones"],
        },
        children: [
          {
            path: "branches",
            name: "operations-branches",
            component: () => import("@/views/app_v2/branches/indexView.vue"),
            meta: {
              pageTitle: "Sucursales",
              breadcrumbs: ["Operaciones", "Sucursales"],
            },
            children: [
              {
                path: "",
                name: "branches-list-cards",
                component: () =>
                  import("@/views/app_v2/branches/ListCards.vue"),
                meta: {
                  pageTitle: "Sucursales",
                  breadcrumbs: ["Operaciones", "Sucursales"],
                },
              },
              {
                path: ":branchId",
                name: "branches-detail",
                component: () => import("@/views/app_v2/branches/Detail.vue"),
                meta: {
                  pageTitle: "Sucursales",
                  breadcrumbs: ["Operaciones", "Sucursales", "Detalle"],
                },
                children: [
                  {
                    path: "", // Ruta vacía para que sea la ruta por defecto
                    name: "branch-detail",
                    redirect: {
                      name: "branch-inspections",
                    },
                  },
                  {
                    path: "inspections",
                    name: "branch-inspections-view",
                    component: () =>
                      import("@/views/app_v2/branches/indexView.vue"),
                    meta: {
                      pageTitle: "Inspecciones",
                      breadcrumbs: [
                        "Operaciones",
                        "Sucursales",
                        "Inspecciones",
                      ],
                    },
                    children: [
                      {
                        path: "",
                        name: "branch-inspections",
                        component: () =>
                          import("@/views/app_v2/branches/Inspections.vue"),
                        meta: {
                          pageTitle: "Inspecciones",
                          breadcrumbs: [
                            "Operaciones",
                            "Sucursales",
                            "Inspecciones",
                          ],
                        },
                      },
                      {
                        path: ":inspectionId",
                        name: "branch-inspection-view",

                        component: () =>
                          import("@/views/app_v2/branches/indexView.vue"),
                        meta: {
                          pageTitle: "Sucursales",
                          breadcrumbs: [
                            "Operaciones",
                            "Sucursales",
                            "Inspecciones",
                          ],
                        },
                        children: [
                          {
                            path: "",
                            name: "branch-inspection-detail",
                            redirect: {
                              name: "branch-inspection-questionnaires",
                            },
                            component: () =>
                              import("@/views/app_v2/branches/Inspection.vue"),
                            meta: {
                              pageTitle: "Inspecciones",
                              breadcrumbs: [
                                "Operaciones",
                                "Sucursales",
                                "Inspecciones",
                              ],
                            },
                            children: [
                              {
                                path: "questionnaires",
                                name: "branch-inspection-questionnaires",
                                component: () =>
                                  import(
                                    "@/views/app_v2/branches/InspectionQuestionnaires.vue"
                                  ),
                                meta: {
                                  pageTitle: "Sucursales",
                                  breadcrumbs: [
                                    "Operaciones",
                                    "Sucursal",
                                    "Inspeccion",
                                    "Cuestionarios",
                                  ],
                                },
                                children: [
                                  {
                                    path: ":questionnaireId",
                                    name: "branch-inspection-questionnaire",
                                    component: () =>
                                      import(
                                        "@/views/app_v2/branches/QuestionnaireDetail.vue"
                                      ),
                                    meta: {
                                      pageTitle: "Sucursales",
                                      breadcrumbs: [
                                        "Operaciones",
                                        "Sucursales",
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "surveys",
                    name: "branch-surveys-view",
                    component: () =>
                      import("@/views/app_v2/branches/indexView.vue"),
                    meta: {
                      pageTitle: "Encuestas",
                      breadcrumbs: ["Operaciones", "Sucursales", "Encuestas"],
                    },
                    children: [
                      {
                        path: "",
                        name: "branch-surveys",
                        component: () =>
                          import("@/views/app_v2/branches/Surveys.vue"),
                        meta: {
                          pageTitle: "Encuestas",
                          breadcrumbs: [
                            "Operaciones",
                            "Sucursales",
                            "Encuestas",
                          ],
                        },
                      },
                    ],
                  },
                  {
                    path: "tickets",
                    name: "branch-tickets-view",
                    component: () =>
                      import("@/views/app_v2/branches/indexView.vue"),
                    meta: {
                      pageTitle: "Encuestas",
                      breadcrumbs: ["Operaciones", "Sucursales", "Encuestas"],
                    },
                    children: [
                      {
                        path: "",
                        name: "branch-tickets",
                        component: () =>
                          import("@/views/app_v2/branches/Tickets.vue"),
                        meta: {
                          pageTitle: "Encuestas",
                          breadcrumbs: [
                            "Operaciones",
                            "Sucursales",
                            "Comentarios / Sugerencias",
                          ],
                        },
                        children: [
                          {
                            path: ":ticketId",
                            name: "branch-ticket-detail",
                            component: () =>
                              import(
                                "@/views/app_v2/tickets/CustomerTicketDetail.vue"
                              ),
                            meta: {
                              pageTitle: "Comentarios / Sugerencias",
                              breadcrumbs: [
                                "Operaciones",
                                "Sucursales",
                                "Comentarios / Sugerencias",
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "screens",
                    name: "branch-screens-view",
                    component: () =>
                      import("@/views/app_v2/branches/indexView.vue"),
                    meta: {
                      pageTitle: "Pantallas",
                      breadcrumbs: ["Operaciones", "Sucursales", "Pantallas"],
                    },
                    children: [
                      {
                        path: "",
                        name: "branch-screens",
                        component: () =>
                          import("@/views/app_v2/branches/Screens.vue"),
                        meta: {
                          pageTitle: "Pantallas",
                          breadcrumbs: [
                            "Operaciones",
                            "Sucursales",
                            "Pantallas",
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "customer-tickets",
            name: "customer-tickets-index",
            component: () => import("@/views/app_v2/branches/indexView.vue"),
            meta: {
              pageTitle: "Sucursales",
              breadcrumbs: ["Operaciones", "Buzón"],
            },
            children: [
              {
                path: "",
                name: "customer-tickets-main",
                component: () =>
                  import("@/views/app_v2/tickets/CustomerTickets.vue"),
                meta: {
                  pageTitle: "Sucursales",
                  breadcrumbs: ["Operaciones", "Buzón"],
                },
              },
              {
                path: "create",
                name: "customer-ticket-create",
                component: () =>
                  import("@/views/app_v2/tickets/CustomerTicketCrud.vue"),
                meta: {
                  pageTitle: "Sucursales",
                  breadcrumbs: ["Operaciones", "Buzón"],
                },
              },
              {
                path: ":ticketId",
                name: "customer-ticket-detail",
                component: () =>
                  import("@/views/app_v2/tickets/CustomerTicketCrud.vue"),
                meta: {
                  pageTitle: "Sucursales",
                  breadcrumbs: ["Operaciones", "Buzón"],
                },
              },
            ],
            // children: [
            //   {
            //     path: "",
            //     name: "customer-tickets-list-cards",
            //     component: () => import("@/views/app_v2/branches/indexView.vue"),
            //     meta: {
            //       pageTitle: "Sucursales",
            //       breadcrumbs: ["Operaciones", "Buzón"],
            //     },

            //   },
            // ],
          },

          {
            path: "questionnaires",
            name: "questionnaires-release-pagination",
            component: () =>
              import("@/views/app_v2/questionnaires/pagination.vue"),
            meta: {
              pageTitle: "Cuestionarios",
              breadcrumbs: ["Operaciones", "Cuestionarios"],
            },
          },
        ],
      },
      {
        path: "/configurations",
        name: "Configuraciones",
        component: () => import("@/views/app_v2/configuration/indexView.vue"),
        meta: {
          pageTitle: "Configuraciones",
          breadcrumbs: ["Configuraciones"],
        },
        children: [
          {
            path: "roles",
            name: "roles-pagination",
            component: () => import("@/views/app_v2/configuration/Roles.vue"),
            meta: {
              pageTitle: "Roles de Usuario",
              breadcrumbs: ["Configuración", "Roles de Usuario"],
            },
          },
          {
            path: "users",
            name: "users-pagination",
            component: () => import("@/views/app_v2/configuration/Users.vue"),
            meta: {
              pageTitle: "Usuarios",
              breadcrumbs: ["Configuración", "Usuarios"],
            },
          },
          {
            path: "branches/:branchId",
            name: "configuration-branche",
            component: () =>
              import("@/views/app_v2/configuration/BranchDetail.vue"),
            meta: {
              pageTitle: "Sucursales",
              breadcrumbs: ["Configuración", "Sucursales", "Detalle"],
            },
          },
          {
            path: "branches",
            name: "branches-pagination",
            component: () =>
              import("@/views/app_v2/configuration/Branches.vue"),
            meta: {
              pageTitle: "Sucursales",
              breadcrumbs: ["Configuración", "Sucursales"],
            },
          },
          {
            path: "templates",
            name: "Plantillas",
            component: () =>
              import("@/views/app_v2/configuration/Templates.vue"),
            meta: {
              pageTitle: "Plantillas de Documentos",
              breadcrumbs: ["Configuración", "Plantillas de Documentos"],
            },
          },
          {
            path: "questionnaires",
            name: "questionnaires-pagination",
            component: () =>
              import("@/views/app_v2/configuration/Questionnaires.vue"),
            meta: {
              pageTitle: "Cuestionarios",
              breadcrumbs: ["Configuración", "Cuestionarios"],
            },
          },
          {
            path: "questionnaires/:id",
            name: "questionnaire-questions",
            component: () =>
              import("@/views/app_v2/configuration/QuestionnaireQuestions.vue"),
            meta: {
              pageTitle: "Cuestionarios",
              breadcrumbs: ["Configuración", "Cuestionarios"],
            },
          },
          {
            path: "processes",
            name: "processes-pagination",
            component: () =>
              import("@/views/app_v2/configuration/Processes.vue"),
            meta: {
              pageTitle: "Cuestionarios",
              breadcrumbs: ["Configuración", "Cuestionarios"],
            },
          },
          {
            path: "surveys",
            name: "surveys-index",
            component: () => import("@/views/app_v2/configuration/Surveys.vue"),
            meta: {
              pageTitle: "Preguntas",
              breadcrumbs: ["Configuración", "Encuestas", "Preguntas"],
            },
          },
          // {
          //   path: "surveys/:surveyId/questions",
          //   name: "survey-questions",
          //   component: () =>
          //     import("@/views/app_v2/configuration/SurveyQuestions.vue"),
          //   meta: {
          //     pageTitle: "Preguntas",
          //     breadcrumbs: ["Configuración", "Encuestas", "Preguntas"],
          //   },
          // },
          {
            path: "screens/videos",
            name: "screens-videos",
            component: () =>
              import("@/views/app_v2/configuration/ScreenVideos.vue"),
            meta: {
              pageTitle: "Video Pantallas",
              breadcrumbs: ["Configuración", "Video Pantallas"],
            },
          },
          {
            path: "devices-iot",
            name: "devices-iot-main",
            component: () => import("@/views/app_v2/configuration/Devices.vue"),
            meta: {
              pageTitle: "Dispositivos IOT",
              breadcrumbs: ["Configuración", "Dispositivos IOT"],
            },
          },
        ],
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/LayoutBuilder.vue"),
        meta: {
          pageTitle: "Layout Builder",
          breadcrumbs: ["Layout"],
        },
      },
      {
        path: "/user",
        name: "user",
        component: () => import("@/views/app_v2/user/index.vue"),
        meta: {
          breadcrumbs: ["Usuario"],
        },
        children: [
          {
            path: "overview",
            name: "overview",
            component: () => import("@/views/app_v2/user/profile/Settings.vue"),
            meta: {
              pageTitle: "Usuario / General",
            },
          },
          {
            path: "settings",
            name: "settings",
            component: () => import("@/views/app_v2/user/profile/Settings.vue"),
            meta: {
              pageTitle: "Usuario / Ajustes",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/app_v2/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        path: "/ev",
        name: "external-view",
        redirect: { name: "branch-sign-in" },
        component: () => import("@/views/indexView.vue"),
        children: [
          {
            path: "branch/:branchId",
            name: "branch-main",
            beforeEnter: (to, from, next) => {
              const branch = localStorage.getItem("branch");
              if (branch) {
                next();
              } else {
                next({ name: "branch-sign-in" });
              }
            },
            component: () => import("@/layouts/SystemLayout.vue"),
            children: [
              {
                path: "",
                name: "branch-menu",
                component: () => import("@/views/branch/Menu.vue"),
                meta: {
                  pageTitle: "Error 404",
                },
              },
              {
                path: "encuesta",
                name: "branch-encuesta",
                component: () => import("@/views/branch/Encuesta.vue"),
                meta: {
                  pageTitle: "Error 404",
                },
              },
              // {
              //   path: "menu-pantallas",
              //   name: "branch-menu-pantallas",
              //   component: () => import("@/views/branch/MenuPantallas.vue"),
              //   meta: {
              //     pageTitle: "Error 404",
              //   },
              // },
            ],
          },
          {
            path: "branch",
            name: "branch-auth",
            redirect: { name: "branch-sign-in" },
            beforeEnter: (to, from, next) => {
              localStorage.removeItem("branch");
              next();
            },
            component: () => import("@/layouts/BranchAuthLayout.vue"),
            children: [
              {
                path: "sign-in",
                name: "branch-sign-in",
                component: () => import("@/views/branch/SignIn.vue"),
                meta: {
                  pageTitle: "Error 404",
                },
              },
              {
                path: "logout",
                name: "branch-logout",
                component: () => import("@/views/app_v2/test/indexView.vue"),
                beforeEnter: (to, from, next) => {
                  localStorage.removeItem("branch");
                  next({ name: "branch-sign-in" });
                },
                meta: {
                  pageTitle: "Error 404",
                },
              },
            ],
          },
          {
            path: "ticket/:folio",
            name: "ticket-customer-detal",
            component: () => import("@/views/tickets/CustomerTicketDetail.vue"),
          },
        ],
      },

      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SimpleLayout.vue"),
    children: [
      // {
      //   path: "/ev",
      //   name: "external-view",
      //   redirect: { name: "branch-sign-in" },
      //   component: () => import("@/views/indexView.vue"),
      //   children: [
      //     {
      //       path: "ticket/:ticketId",
      //       name: "ticket-customer-detal",
      //       component: () => import("@/views/tickets/CustomerTicketDetail.vue"),
      //     },
      //   ],
      // },

      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/dashboard",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log(`From: ${String(from.path)}, To: ${String(to.path)}`);
  // current page view title
  document.title = `${process.env.VUE_APP_NAME} / ${to.meta.pageTitle}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    // verify auth token before each page change
    // store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });
    const AuthStore = useAuthStore();
    console.log("go to verifyAuth");
    await AuthStore.verifyAuth(AuthStore.getToken());

    // console.log(`isUserAuthenticated 1 ?: ${AuthStore.isUserAuthenticated()}`);
    if (AuthStore.isUserAuthenticated()) {
      next();
    } else {
      next({ name: "sign-in" });
    }
  } else {
    // console.log(`isUserAuthenticated 2 ?: ${AuthStore.isUserAuthenticated()}`);
    console.log("Puede continuar");
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
